import { jsx as _jsx } from "react/jsx-runtime";
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@app/store/Hooks';
import { cancelEditStep, setPaymentMethod, submitStep, } from '@entities/portfolio';
import PaymentSelect from '@shared/ui/misc/PaymentSelect';
import { ContainerRow } from '@src/components/styled';
import IconText from '@shared/ui/icons/IconText';
import PaymentSystemIcon from '@shared/ui/icons/PaymentSystemIcon';
import { IsDefined } from '@shared/lib/js-ts';
import BidToQueueStep from './BidToQueueStep';
const Form = styled.form();
const StepName = 'payment_method';
const TextRow = styled(ContainerRow)(() => ({
    height: 'auto',
    alignItems: 'center',
    gap: 7,
}));
const PaymentMethodCollapsed = () => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const { subscriptionData } = useAppSelector(state => state.portfolioSubscription);
    return (_jsx(TextRow, { children: _jsx(IconText, { IconComponent: _jsx(PaymentSystemIcon, { type: (_a = subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.paymentMethod) === null || _a === void 0 ? void 0 : _a.type }), text: t('portfolio.subscription.steps.payment_method.select_method_collapsed', {
                methodName: ((_b = subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.paymentMethod) === null || _b === void 0 ? void 0 : _b.type) || '',
                cardNumber: ((_c = subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.paymentMethod) === null || _c === void 0 ? void 0 : _c.number) || '',
            }) }) }));
};
const PaymentMethodStep = ({ stepName, }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { subscriptionData } = useAppSelector(state => state.portfolioSubscription);
    const { handleSubmit, setValue, watch } = useForm({ defaultValues: subscriptionData });
    const paymentMethod = watch('paymentMethod');
    const onSubmit = (values) => {
        if (IsDefined(values.paymentMethod)) {
            dispatch(setPaymentMethod(values.paymentMethod));
            dispatch(submitStep(StepName));
        }
    };
    const onAction = (actionType) => {
        if (actionType === 'update' && IsDefined(paymentMethod)) {
            dispatch(setPaymentMethod(paymentMethod));
            dispatch(cancelEditStep(stepName));
        }
        else if (actionType === 'cancel' && IsDefined(subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.paymentMethod)) {
            setValue('paymentMethod.number', subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.paymentMethod.number);
            setValue('paymentMethod.type', subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.paymentMethod.type);
        }
    };
    const handleMethodSelect = (option) => {
        setValue('paymentMethod', {
            number: option.value,
            type: option.paymentSystem,
        });
    };
    return (_jsx(Form, { onSubmit: handleSubmit(onSubmit), children: _jsx(BidToQueueStep, { stepName: StepName, title: t('portfolio.subscription.steps.payment_method.label'), tooltipText: t('portfolio.subscription.steps.payment_method.tooltip'), buttonsDisabled: !IsDefined(paymentMethod), onAction: onAction, 
            // errorMessage={errorMessage}
            collapsedContent: _jsx(PaymentMethodCollapsed, {}), children: _jsx(PaymentSelect, { options: [], paymentErrorText: '', onChange: handleMethodSelect, renderEmptyValue: t('portfolio.subscription.steps.payment_method.placeholder') }) }) }));
};
export default PaymentMethodStep;
